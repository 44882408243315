import React from "react"
import "./blogPost.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import quote from "../../images/quote-marks-hixon.svg"
//Import Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import {
  faPinterest,
  faFacebookF,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
//Implementation of Share Buttons Component
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share"

function BlogPost({ post }) {
  //Social Sharing URL
  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <section className="blogPost">
      <div className="post-up-container">
        <div className="post-date pb-2">{post?.date}</div>
        <div className="post-author pb-2">By &nbsp;Hixon Mortgage</div>
        <div className="gray-bar mb-2"></div>
      </div>

      <div className="post-content-container mb-2">
        <GatsbyImage
          image={getImage(post?.featuredImage?.node.localFile)}
          alt="Blog image"
        />
        <div className="post-meta">
          <div className="post-date pb-2">{post?.date}</div> {" | "}
          <div className="post-author pb-2">By &nbsp;Hixon Mortgage</div>
        </div>
        <h1 className="post-title">{post?.title}</h1>
        <div
          className="post-up-container main_content"
          dangerouslySetInnerHTML={{ __html: post?.content }}
        ></div>
        {/*<div className="post-quote-container">
          <div className="brown-bar"></div>
          <div className="quote-main">
            <img src={quote} alt="quote mark" className="quote-icon" />
            <div className="quote-content">{post?.postQuote?.quoteText}</div>
            <div className="quote-author sec-title">
              {post?.postQuote?.author}
            </div>
          </div>
        </div>*/}
        <div className="post-socials-container d-flex flex-row align-items-center pt-5">
          <div className="text-bold">Share this post</div>
          <div className="socials-container mx-5">
            <ul className="d-flex flex-row">
              <li className="px-2">
                <a>
                  <FacebookShareButton url={url} title={post?.title}>
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="social-icon"
                    />
                  </FacebookShareButton>
                </a>
              </li>
              <li className="px-2">
                <a>
                  <TwitterShareButton url={url} title={post?.title}>
                    <FontAwesomeIcon icon={faTwitter} className="social-icon" />
                  </TwitterShareButton>
                </a>
              </li>
              <li className="px-2">
                <a>
                  <PinterestShareButton
                    url={url}
                    title={post?.title}
                    media={post?.featuredImage?.node?.sourceUrl}
                  >
                    <FontAwesomeIcon
                      icon={faPinterest}
                      className="social-icon"
                    />
                  </PinterestShareButton>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogPost
