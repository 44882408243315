import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons"
import "./postNavigation.scss"
const PostNavigation = ({ prev = null, next = null, ...props }) => {
  if (!prev && !next) {
    return null
  }

  return (
    <nav {...props} className="post-navigation">
      <div className={`page-item prev-btn ${!prev ? "disabled" : ""}`}>
        <Link className="page-link" to={"/news/" + prev.slug} rel="prev">
          <FontAwesomeIcon
            icon={faAnglesLeft}
            style={{ width: "16px", height: "12px" }}
            className="page-icon"
          />
          &nbsp; Prev
        </Link>
      </div>
      <div className={`page-item ${!next ? "disabled" : ""}`}>
        <Link
          className="page-link next-btn"
          to={"/news/" + next.slug}
          rel="next"
        >
          Next &nbsp;
          <FontAwesomeIcon
            icon={faAnglesRight}
            style={{ width: "16px", height: "12px" }}
            className="page-icon"
          />
        </Link>
      </div>
    </nav>
  )
}

export default PostNavigation
