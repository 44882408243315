import React from "react"
import BlogPost from "../../components/BlogPost/blogPost"
import Layout from "../../components/Layouts/layout"
import PostNavigation from "../../components/PostNavigation/PostNavigation"
import "./index.scss"
import { graphql } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo'

function newsPost({ data, pageContext }) {
  const { prev, next } = pageContext
  let post = data?.wpPost
  const seo = data?.wpPost?.seo
  return (
    <Layout>
      <Seo post={post} />
      <PostNavigation prev={prev} next={next} />
      <BlogPost post={post} />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      title
      excerpt
      slug
      content
      date(formatString: "MM. D.YY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          sourceUrl
        }
      }
      postQuote {
        quoteText
        author
      }
    }
  }
`
export default newsPost
